var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "d": ['none', 'flex'],
      "my": "1rem",
      "mx": "0",
      "flex-shrink": "0",
      "pos": "sticky",
      "top": "100px",
      "h": "fit-content",
      "transition-duration": "300ms",
      "flex-grow": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "pr": "16px",
      "w": "100%"
    }
  }, [_vm._l(_vm.sideMenu, function (menu) {
    return [menu.route != null ? _c('c-button', {
      key: menu.name,
      attrs: {
        "variant": "ghost",
        "as": "router-link",
        "to": menu.route,
        "d": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "start",
        "mb": "16px",
        "pl": "1rem",
        "pr": "8px",
        "background": _vm.open ? menu.isActive ? '#F2F2F2' : 'transparent' : '#F2F2F2',
        "border-left": _vm.open ? menu.isActive ? '8px solid #008C81' : '8px solid transparent' : menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2',
        "border-radius": "8px",
        "h": "60px"
      }
    }, [_c('c-image', {
      staticClass: "icon",
      attrs: {
        "alt": menu.name,
        "src": menu.icon,
        "w": "24px",
        "h": "24px"
      }
    }), _vm.open ? _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "font-family": "Roboto",
        "color": menu.isActive ? '#008C81' : '#555555',
        "ml": "12px"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]) : _vm._e()], 1) : _c('c-flex', {
      key: menu.name,
      attrs: {
        "flex-direction": "column",
        "w": "100%"
      }
    }, [_c('c-button', {
      attrs: {
        "variant": "ghost",
        "d": "flex",
        "flex-direction": "row",
        "align-items": "center",
        "justify-content": "start",
        "pl": "1rem",
        "pr": "8px",
        "background": _vm.open ? menu.isActive ? '#F2F2F2' : 'transparent' : '#F2F2F2',
        "border-left": _vm.open ? menu.isActive ? '8px solid #008C81' : '8px solid transparent' : menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2',
        "border-radius": "8px",
        "h": "60px"
      },
      on: {
        "click": function click($event) {
          return _vm.toggleOpenProfile();
        }
      }
    }, [_c('c-image', {
      staticClass: "icon",
      attrs: {
        "alt": menu.name,
        "src": menu.icon,
        "w": "24px",
        "h": "24px"
      }
    }), _vm.open ? _c('c-text', {
      attrs: {
        "font-size": "16px",
        "font-weight": "400",
        "font-family": "Roboto",
        "color": menu.isActive ? '#008C81' : '#555555',
        "ml": "12px"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")]) : _vm._e()], 1), _c('c-button-group', {
      attrs: {
        "d": _vm.open ? _vm.isOpenProfile ? 'flex' : 'none' : 'none',
        "flex-direction": "column",
        "mt": "8px",
        "mx": "auto",
        "w": "100%",
        "pl": "1rem",
        "pr": "8px"
      }
    }, _vm._l(menu.children, function (child) {
      return _c('c-button', {
        key: child.name,
        attrs: {
          "as": "router-link",
          "to": child.route,
          "variant": "ghost",
          "mx": "auto",
          "d": "flex",
          "w": "100%",
          "px": "16px",
          "flex-direction": "row",
          "align-items": "center",
          "justify-content": "start",
          "border-bottom": child.isActive ? '1px solid #008C81' : '1px solid #C4C4C4',
          "border-radius": "0px",
          "mb": "8px"
        }
      }, [_c('c-text', {
        attrs: {
          "font-size": "16px",
          "color": child.isActive ? '#008C81' : '#888888',
          "font-family": "Roboto",
          "font-weight": "400"
        }
      }, [_vm._v(" " + _vm._s(child.name) + " ")])], 1);
    }), 1)], 1)];
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }