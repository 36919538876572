var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "id": "bottomNavigation",
      "as": "nav",
      "pos": "fixed",
      "left": "0",
      "bottom": "0px",
      "w": "100vw",
      "bg": "white",
      "border-top": "1px solid #C4C4C4",
      "align-items": "center",
      "justify-content": "space-around",
      "display": ['grid', 'none'],
      "template-columns": ['repeat(3, 1fr)'],
      "z-index": "11"
    }
  }, [_vm._l(_vm.menus, function (menu) {
    return [_c('c-flex', {
      key: menu.name,
      class: menu.className,
      attrs: {
        "width": "100%",
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "cursor": "pointer",
        "h": "64px"
      },
      on: {
        "click": function click($event) {
          return _vm.onMenuClicked(menu);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "width": "24px",
        "height": "24px",
        "color": menu.isActive ? '#008C81' : '#555',
        "margin-bottom": "4px"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": menu.icon,
        "height": "100%",
        "width": "100%",
        "fill": "currentColor"
      }
    })], 1), _c('c-text', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          '&.active': {
            color: 'primary.400'
          }
        },
        expression: "{\n          '&.active': {\n            color: 'primary.400',\n          },\n        }"
      }],
      class: {
        active: menu.isActive
      },
      attrs: {
        "font-size": "12px",
        "font-weight": "400",
        "font-family": "Roboto",
        "color": "#555555"
      }
    }, [_vm._v(" " + _vm._s(menu.name) + " ")])], 1)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }