<template>
  <c-flex
    :d="['none', 'flex']"
    my="1rem"
    mx="0"
    flex-shrink="0"
    pos="sticky"
    top="100px"
    h="fit-content"
    transition-duration="300ms"
    flex-grow="1"
    z-index="20"
  >
    <c-flex
      flex-direction="column"
      pr="16px"
      w="100%"
    >
      <c-box
        v-for="menu in menus"
        :key="menu.name"
        mb="16px"
      >
        <c-button
          :class="menu.className"
          variant="ghost"
          as="router-link"
          :to="menu.route"
          d="flex"
          flex-direction="row"
          align-items="center"
          justify-content="start"
          pl="1rem"
          pr="8px"
          :background="open ? (menu.isActive ? '#F2F2F2' : 'transparent') : '#F2F2F2'"
          :border-left="open ? (menu.isActive ? '8px solid #008C81' : '8px solid transparent') : (menu.isActive ? '8px solid #008C81' : '8px solid #F2F2F2')"
          border-radius="8px"
          h="60px"
        >
          <c-box
            width="24px"
            height="24px"
            :color="menu.isActive ? '#008C81' : '#555'"
          >
            <inline-svg
              :src="menu.icon"
              height="100%"
              width="100%"
              fill="currentColor"
            />
          </c-box>
          <c-text
            v-if="open"
            font-size="16px"
            font-weight="400"
            font-family="Roboto"
            :color="menu.isActive ? '#008C81' : '#555555'"
            ml="12px"
          >
            {{ menu.name }}
          </c-text>
        </c-button>
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>

export default {
  name: 'MealPlanSideMenu',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    menus() {
      const self = this
      return [
        {
          name: 'Rencana Makan',
          route: {
            name: 'client.meal-plan.meal-plan-schedule',
            params: {
              month: self.$route.params?.month || 1,
            },
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-calendar-today-alt.svg')
          },
          get isActive() {
            return (
              self.$route.name.includes('client.meal-plan.meal-plan-schedule')
            )
          },
          className: 'client_meal-plan_meal-plan-schedule_detail_1-desk',
        },
        {
          name: 'Rekomendasi Menu',
          route: {
            name: 'client.meal-plan.menu-recommendation',
            params: {
              month: self.$route.params?.month || 1,
            },
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-restaurant-menu.svg')
          },
          get isActive() {
            return (
              self.$route.name === 'client.meal-plan.menu-recommendation'
            )
          },
          className: 'client_meal-plan_menu-recommendation_1-desk',
        },
        {
          name: 'Profil Gizi',
          route: {
            name: 'client.meal-plan.profile',
            params: {
              month: self.$route.params?.month || 1,
            },
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-list-alt.svg')
          },
          get isActive() {
            return self.$route.name === 'client.meal-plan.profile'
          },
          className: 'client_meal-plan_profile_1-desk',
        },
      ]
    },
  },
  methods: {
  },
}
</script>

<style scoped>
::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}
</style>