<template>
  <c-grid
    id="bottomNavigation"
    as="nav"
    pos="fixed"
    left="0"
    bottom="0px"
    w="100vw"
    bg="white"
    border-top="1px solid #C4C4C4"
    align-items="center"
    justify-content="space-around"
    :display="['grid', 'none']"
    :template-columns="['repeat(3, 1fr)']"
    z-index="11"
  >
    <template v-for="menu in menus">
      <c-flex
        :key="menu.name"
        width="100%"
        flex-direction="column"
        justify-content="center"
        align-items="center"
        cursor="pointer"
        h="64px"
        :class="menu.className"
        @click="onMenuClicked(menu)"
      >
        <c-box
          width="24px"
          height="24px"
          :color="menu.isActive ? '#008C81' : '#555'"
          margin-bottom="4px"
        >
          <inline-svg
            :src="menu.icon"
            height="100%"
            width="100%"
            fill="currentColor"
          />
        </c-box>
        <c-text
          v-chakra="{
            '&.active': {
              color: 'primary.400',
            },
          }"
          :class="{ active: menu.isActive }"
          font-size="12px"
          font-weight="400"
          font-family="Roboto"
          color="#555555"
        >
          {{ menu.name }}
        </c-text>
      </c-flex>
    </template>
  </c-grid>
</template>

<script>
export default {
  name: 'MealPlanNavBottom',
  components: {},
  data() {
    return {}
  },
  computed: {
    menus() {
      const self = this
      return [
        {
          name: 'Rencana Makan',
          route: {
            name: 'client.meal-plan.meal-plan-schedule',
            params: {
              month: self.$route.params?.month || 1,
            },
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-calendar-today-alt.svg')
          },
          get isActive() {
            return (
              self.$route.name.includes('client.meal-plan.meal-plan-schedule')
            )
          },
          className: 'client_meal-plan_meal-plan-schedule_detail_1',
        },
        {
          name: 'Rekomendasi Menu',
          route: {
            name: 'client.meal-plan.menu-recommendation',
            params: self.$route.params,
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-restaurant-menu.svg')
          },
          get isActive() {
            return (
              self.$route.name === 'client.meal-plan.menu-recommendation'
            )
          },
          className: 'client_meal-plan_menu-recommendation_1',
        },
        {
          name: 'Profil Gizi',
          route: {
            name: 'client.meal-plan.profile',
            params: self.$route.params,
            query: {
              ...self.$route.query,
            },
          },
          get icon() {
            return require('@/assets/icons/icon-list-alt.svg')
          },
          get isActive() {
            return self.$route.name === 'client.meal-plan.profile'
          },
          className: 'client_meal-plan_profile_1',
        },
      ]
    },
  },
  methods: {
    onMenuClicked(menu) {
      this.$router.push(menu.route)
    },
  },
}
</script>

<style scoped>
::v-deep .v-tour__target--highlighted {
  box-shadow: 0 0 0 99999px rgba(0,0,0,.4);
}
</style>